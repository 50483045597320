<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="350"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal"
  >
    <v-form
      @submit.prevent="execute"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-card class="b-dialog-question-card">
        <div class="b-dialog-question-card--header">
          <v-icon color="primary" size="40">
            fa-regular fa-pen-to-square
          </v-icon>
        </div>
        <div class="b-dialog-question-card--body">
          <h3>Alteração do status do chamado</h3>
          <v-autocomplete
            id="situation"
            name="situation"
            label="Status"
            outlined
            dense
            required
            :rules="[required]"
            :items="filteredItems"
            v-model="situation"
          />
        </div>
        <div class="b-dialog-question-card--footer">
          <BaseButton
            id="btn-back"
            name="btn-back"
            outlined
            color="primary"
            title="Voltar"
            @click="closeModal"
          />
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Alterar"
            :disabled="!valid"
          />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { supportMixin } from '@/mixins/support';
import { showMessage } from '@/helpers/messages';
import { SupportService } from '@/services/api/support';

export default {
  mixins: [rulesMixin, supportMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    ticketId: {
      type: String,
      required: true
    },
    currentStatus: {
      type: String,
      required: true
    }
  },

  data: () => ({
    valid: true,
    situation: null,
    items: ['Em andamento', 'Aguardando operadora']
  }),

  computed: {
    filteredItems() {
      return this.items.filter(x => x !== this.currentStatus);
    }
  },

  methods: {
    closeModal() {
      this.$emit('closeModal');
    },

    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const supportService = new SupportService();
        const { status } = await supportService.putTicketSituation({
          ticketId: this.ticketId,
          situation: this.situation
        });

        if (status === 204) {
          this.getSupportById(this.ticketId);
          showMessage('success', 'Status atualizado com sucesso');
          this.closeModal();
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
